import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/diarcastro/clients/cure-foundation/looking-forward/src/templates/static-content-template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ChapterTitle = makeShortcode("ChapterTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ChapterTitle chapter="Chapter 1" title="WHAT’S NEXT?" subtitle="Being mindful about your 'new normal'" mdxType="ChapterTitle" />
    <p>{`“Cancer survivors: Managing your emotions after cancer treatment.” `}<em parentName="p">{`Mayo Clinic`}</em>{`, 2014, `}<a parentName="p" {...{
        "href": "http://www.mayoclinic.org/diseases-conditions/cancer/indepth/cancer-survivor/art-20047129",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mayoclinic.org/diseases-conditions/cancer/indepth/cancer-survivor/art-20047129`}</a>{`. Accessed 1 July 2016.`}</p>
    <p>{`“Emotional Side Effects.” `}<em parentName="p">{`American Cancer Society`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.org/treatment/treatmentsandsideeffects/emotionalsideeffects/index",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/treatment/treatmentsandsideeffects/emotionalsideeffects/index`}</a>{`. Accessed 1 July 2016.`}</p>
    <p>{`“Emotions After Cancer Treatment.” `}<em parentName="p">{`Livestrong Foundation`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.livestrong.org/we-can-help/finishing-treatment/emotions-after-cancer-treatment",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.livestrong.org/we-can-help/finishing-treatment/emotions-after-cancer-treatment`}</a>{`. Accessed 1 July 2016.`}</p>
    <p>{`“Facing Forward: Life After Cancer Treatment.” `}<em parentName="p">{`National Cancer Institute`}</em>{`, 2014, `}<a parentName="p" {...{
        "href": "http://www.cancer.gov/publications/patient-education/facing-forward",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.gov/publications/patient-education/facing-forward`}</a>{`. Accessed 22 February 2016.`}</p>
    <p>{`Magee, Sherri, and Kathy Scalzo. `}<em parentName="p">{`Picking Up the pieces: Moving Forward After Surviving Cancer`}</em>{`. Vancouver: Raincoast Books, 2006.`}</p>
    <p>{`Proust, Marcel in Magee, Sherri and Kathy Scalzo. `}<em parentName="p">{`Picking Up the Pieces: Moving Forward After Surviving Cancer`}</em>{`. Vancouver: Raincoast Books, 2006, p. 40.`}</p>
    <p>{`“Your Emotions.” `}<em parentName="p">{`MacMillan Cancer Support`}</em>{`, 2014, `}<a parentName="p" {...{
        "href": "http://www.macmillan.org.uk/information-and-support/coping/your-emotions/dealing-with-youremotions",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.macmillan.org.uk/information-and-support/coping/your-emotions/dealing-with-youremotions`}</a>{`. Accessed 1 July 2016`}</p>
    <p>{`“Your feelings after cancer treatment.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/en/cancer-information/cancer-journey/life-after-cancer/your-feelings-after-cancer/?region=qc",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/en/cancer-information/cancer-journey/life-after-cancer/your-feelings-after-cancer/?region=qc`}</a>{`. Accessed 1 July 2016.`}</p>
    <ChapterTitle chapter="Chapter 2" title="WHAT TO EXPECT AFTER TREATMENT" subtitle="Managing side effects and their symptoms" mdxType="ChapterTitle" />
    <p>{`“Attention, Thinking, or Memory Problems.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, 2015, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/navigating-cancer-care/side-effects/attention-thinking-or-memory-problems",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/navigating-cancer-care/side-effects/attention-thinking-or-memory-problems`}</a>{`. Accessed 14 March 2016.`}</p>
    <p>{`Bernstein, Lori J. “Cancer-Related Brain Fog: Information for Cancer Patients and Caregivers About Cancer-Related Brain Fog.” `}<em parentName="p">{`UHN`}</em>{`, August 2014, `}<a parentName="p" {...{
        "href": "http://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Cancer_Related_Brain_Fog.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Cancer_Related_Brain_Fog.pdf`}</a>{`. Accessed 14 March 2016.`}</p>
    <p>{`“Bowel Disease: Caring for Your Ostomy.” `}<em parentName="p">{`HealthLinkBC`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.healthlinkbc.ca/health-topics/ug2171",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.healthlinkbc.ca/health-topics/ug2171`}</a>{`.  Accessed 9 October 2020.`}</p>
    <p>{`“Caring for an Ileostomy.” `}<em parentName="p">{`American Cancer Society`}</em>{`, October 2019, `}<a parentName="p" {...{
        "href": "http://www.cancer.org/treatment/treatments-and-side-effects/treatment-types/surgery/ostomies/ileostomy/management.html",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/treatment/treatments-and-side-effects/treatment-types/surgery/ostomies/ileostomy/management.html`}</a>{`. Accessed 14 October 2020`}</p>
    <p>{`“Complementary Therapies.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, May 2014, `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/~/media/cancer.ca/CW/publications/Complementary%20therapies/Complementary-therapies-2016-EN.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca`}{`~`}{`/media/cancer.ca/CW/publications/Complementary therapies/Complementary-therapies-2016-EN.pdf`}</a>{`. Accessed 30 October 2015.`}</p>
    <p>{`“Coping with Cancer Related Fatigue.” `}<em parentName="p">{`Leukemia and Lymphoma Society of Canada`}</em>{`, 2009, `}{`[www.llscanada.org/sites/default/files/National/CANADA/Pdf/LLSC Coping with Cancer Related Fatigue Booklet - English.pdf]`}{`(`}<a parentName="p" {...{
        "href": "http://www.llscanada.org/sites/default/files/National/CANADA/Pdf/LLSC",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.llscanada.org/sites/default/files/National/CANADA/Pdf/LLSC`}</a>{` Coping with Cancer Related Fatigue Booklet - English.pdf). Accessed 14 March 2016.`}</p>
    <p>{`“Coping with Chemo Brain.” `}<em parentName="p">{`Leukemia and Lymphoma Society of Canada`}</em>{`, 2012, `}{`[www.llscanada.org/sites/default/files/National/CANADA/Pdf/LLSC Coping with Chemobrain Booklet - English.pdf]`}{`(`}<a parentName="p" {...{
        "href": "http://www.llscanada.org/sites/default/files/National/CANADA/Pdf/LLSC",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.llscanada.org/sites/default/files/National/CANADA/Pdf/LLSC`}</a>{` Coping with Chemobrain Booklet - English.pdf). Accessed 14 March 2016.`}</p>
    <p>{`“Creating an Action Plan for Managing Your Fatigue.” `}<em parentName="p">{`My Cancer Fatigue`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.mycancerfatigue.ca/Home/My_Action_Plan",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mycancerfatigue.ca/Home/My_Action_Plan`}</a>{`. Accessed 23 November 2020.`}</p>
    <p>{`Drysdale, Elaine. “Chemo-Brain: Coping with Cognitive Change.” Forward, vol 2, no. 2, Fall 2014. BC Cancer Agency, `}<a parentName="p" {...{
        "href": "https://digital.canadawide.com/i/389164-fall-2014",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`digital.canadawide.com/i/389164-fall-2014`}</a>{`. Accessed 14 March 2016`}</p>
    <p>{`“Facing Forward: Life After Cancer Treatment.” `}<em parentName="p">{`National Cancer Institute`}</em>{`, 2014, `}<a parentName="p" {...{
        "href": "http://www.cancer.gov/publications/patient-education/facing-forward",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.gov/publications/patient-education/facing-forward`}</a>{`. Accessed 22 February 2016.`}</p>
    <p>{`“Five Solutions for Menopause Symptoms.” `}<em parentName="p">{`The North American Menopause Society`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.menopause.org/for-women/menopauseflashes/menopause-symptoms-and-treatments/five-solutions-for-menopause-symptoms",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.menopause.org/for-women/menopauseflashes/menopause-symptoms-and-treatments/five-solutions-for-menopause-symptoms`}</a>{`. Accessed 18 February 2021`}</p>
    <p>{`Hammar, Mats, and Richard Lindgren. “Does physical exercise influence the frequency of postmenopausal hot flushes?” `}<em parentName="p">{`Acta obstetricia et gynecologica Scandinavica`}</em>{` 69.5 (1990): 409-412.`}</p>
    <p>{`“Hormonal Symptoms – Prostate Cancer.” `}<em parentName="p">{`Cancer Care Ontario`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancercareontario.ca/en/symptom-management/35046",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercareontario.ca/en/symptom-management/35046`}</a>{`. Accessed 6 November 2020.`}</p>
    <p>{`“Hormonal therapy.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/en/cancer-information/diagnosis-and-treatment/chemotherapy-and-other-drug-therapies/hormonal-therapy/?region=on",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/en/cancer-information/diagnosis-and-treatment/chemotherapy-and-other-drug-therapies/hormonal-therapy/?region=on`}</a>{`. Accessed 6 November 2020.`}</p>
    <p>{`“Hormonal Therapy: Managing Side Effects in Women.” `}<em parentName="p">{`University of Rochester Medical Center`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.urmc.rochester.edu/encyclopedia/content.aspx?contenttypeid=34&contentid=26606-1",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.urmc.rochester.edu/encyclopedia/content.aspx?contenttypeid=34&contentid=26606-1`}</a>{`. Accessed 18 February 2021.`}</p>
    <p>{`“Hormone Deprivation Symptoms in Men.” `}<em parentName="p">{`Cancer.Net`}</em>{`, October 2019, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/coping-with-cancer/physical-emotional-and-social-effects-cancer/managing-physical-side-effects/hormone-deprivation-symptoms-men",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/coping-with-cancer/physical-emotional-and-social-effects-cancer/managing-physical-side-effects/hormone-deprivation-symptoms-men`}</a>{`. Accessed 6 November 2020.`}</p>
    <p>{`“Hormone Therapy and Breast Cancer.” `}<em parentName="p">{`Breast Cancer Network Australia`}</em>{`, September 2017, `}<a parentName="p" {...{
        "href": "http://www.bcna.org.au/media/7663/bcna_hormone-booklet-webpdf_oct2019.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.bcna.org.au/media/7663/bcna_hormone-booklet-webpdf_oct2019.pdf`}</a>{`. Accessed 13 February 2021.`}</p>
    <p>{`“Hormone Therapy for Breast Cancer.” `}<em parentName="p">{`National Cancer Institute`}</em>{`, June 2020, `}<a parentName="p" {...{
        "href": "http://www.cancer.gov/types/breast/breast-hormone-therapy-fact-sheet",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.gov/types/breast/breast-hormone-therapy-fact-sheet`}</a>{`. Accessed 8 October 2020`}</p>
    <p>{`“Hormone Therapy for Prostate Cancer.” `}<em parentName="p">{`American Cancer Society`}</em>{`, December 2019, `}<a parentName="p" {...{
        "href": "http://www.cancer.org/cancer/prostate-cancer/treating/hormone-therapy.html",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/cancer/prostate-cancer/treating/hormone-therapy.html`}</a>{`. Accessed 7 December 2020.`}</p>
    <p>{`“Hormone Therapy for Prostate Cancer.” `}<em parentName="p">{`National Cancer Institute`}</em>{`, February 2019, `}<a parentName="p" {...{
        "href": "http://www.cancer.gov/types/prostate/prostate-hormone-therapy-fact-sheet",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.gov/types/prostate/prostate-hormone-therapy-fact-sheet`}</a>{`. Accessed 8 October 2020.`}</p>
    <p>{`“Hormone Therapy: The Basics.” `}<em parentName="p">{`OncoLink`}</em>{`, February 2020, `}<a parentName="p" {...{
        "href": "http://www.oncolink.org/cancer-treatment/hormone-therapy/hormone-therapy-the-basics",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.oncolink.org/cancer-treatment/hormone-therapy/hormone-therapy-the-basics`}</a>{`. Accessed 9 October 2020.`}</p>
    <p>{`“Hot flashes in men: An update.” `}<em parentName="p">{`Harvard Health Publishing`}</em>{`, March 2014, `}<a parentName="p" {...{
        "href": "http://www.health.harvard.edu/newsletter_article/Hot-flashes-in-men-An-update",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.health.harvard.edu/newsletter_article/Hot-flashes-in-men-An-update`}</a>{`. Accessed 6 November 2020`}</p>
    <p>{`“How to Live With an Ostomy Bag.” `}<em parentName="p">{`Cancer.Net`}</em>{`, June 2019, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/navigating-cancer-care/how-cancer-treated/surgery/types-ostomy/how-live-with-ostomy-bag",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/navigating-cancer-care/how-cancer-treated/surgery/types-ostomy/how-live-with-ostomy-bag`}</a>{`. Accessed 8 October 2020`}</p>
    <p>{`Howell, Doris, and ELLICSR Research Team. “Managing Bowel Problems after Cancer Treatment.” `}<em parentName="p">{`UHN`}</em>{`, 2013, `}<a parentName="p" {...{
        "href": "http://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Bowel_Problems_after_Cancer_Treatment.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Bowel_Problems_after_Cancer_Treatment.pdf`}</a>{`. Accessed 14 March 2016.`}</p>
    <p>{`Howell, Doris, Rob Hamilton, and ELLICSR Research Team. “Managing Changes in Your Bladder Function after Cancer Treatment.” `}<em parentName="p">{`UHN`}</em>{`, 2013, `}<a parentName="p" {...{
        "href": "http://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Changes_in_Bladder_Function_after_Cancer_Treatment.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Changes_in_Bladder_Function_after_Cancer_Treatment.pdf`}</a>{`. Accessed 14 March 2016.`}</p>
    <p>{`“How to Manage Your Fatigue.” `}<em parentName="p">{`Cancer Care Ontario`}</em>{`, 2015, `}<a parentName="p" {...{
        "href": "http://www.cancercare.on.ca/common/pages/UserFile.aspx?fileId=348063",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.on.ca/common/pages/UserFile.aspx?fileId=348063`}</a>{`. Accessed 15 March 2016.`}</p>
    <p>{`Incekol, Diana, and Cindy Murray. “Managing the Side Effects of Chemotherapy.” `}<em parentName="p">{`UHN`}</em>{`, 2013, `}<a parentName="p" {...{
        "href": "http://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_the_Side_Effects_of_Chemotherapy.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_the_Side_Effects_of_Chemotherapy.pdf`}</a>{`. Accessed 14 March 2016.`}</p>
    <p>{`“Life after Cancer Treatment.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, May 2013, `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/~/media/cancer.ca/CW/publications/Life%20after%20cancer%20treatment/Life-%20After-Cancer-Treatment-EN-May%202016.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Life after cancer treatment/Life- After-Cancer-Treatment-EN-May 2016.pdf`}</a>{`. Accessed 30 October 2015.`}</p>
    <p>{`“Life after Cancer Treatment: Osteoporosis.” 2013, pp.50. `}<em parentName="p">{`Canadian Cancer Society`}</em>{`. `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/~/media/cancer.ca/CW/publications/Life%20after%20cancer%20treatment/32060-1-NO.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Life after cancer treatment/32060-1-NO.pdf`}</a>{`. Accessed 1 December 2020.`}</p>
    <p>{`“Living with an ostomy.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/en/cancer-information/diagnosis-and-treatment/rehabilitation/living-with-an-ostomy/?region=on",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/en/cancer-information/diagnosis-and-treatment/rehabilitation/living-with-an-ostomy/?region=on`}</a>{`. Accessed 14 October 2020.`}</p>
    <p>{`“Living with IBD and an ostomy.” `}<em parentName="p">{`Vegan Ostomy`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.veganostomy.ca",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.veganostomy.ca`}</a>{`. Accessed 10 October 2020.`}</p>
    <p>{`“Long-Term Side Effects of Cancer Treatment.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, 2015, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/survivorship/long-term-side-effectscancer-treatment",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/long-term-side-effectscancer-treatment`}</a>{`. Accessed 14 March 2016.`}</p>
    <p>{`“Lymphedema.” `}<em parentName="p">{`BC Cancer Agency`}</em>{`, 2014, `}<a parentName="p" {...{
        "href": "http://www.bccancer.bc.ca/health-info/types-of-cancer/breast-cancer/breast/lymphedema",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.bccancer.bc.ca/health-info/types-of-cancer/breast-cancer/breast/lymphedema`}</a>{`. Accessed March 2016`}</p>
    <p>{`Mayo Clinic Staff. “Cancer Fatigue: Why It Occurs and How to Cope.” `}<em parentName="p">{`Mayo Foundation for Medical Education and Research`}</em>{`, 2014, `}<a parentName="p" {...{
        "href": "http://www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-fatigue/art-20047709",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-fatigue/art-20047709`}</a>{`. Accessed 15 March 2016.`}</p>
    <p>{`“Menopausal Symptoms in Women.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, February 2016, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/navigating-cancer-care/side-effects/menopausal-symptoms-women",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/navigating-cancer-care/side-effects/menopausal-symptoms-women`}</a>{`. Accessed 27 June 2016.`}</p>
    <p>{`“Ostomy: Adapting to life after colostomy, ileostomy or urostomy.” `}<em parentName="p">{`Mayo Clinic`}</em>{`, November 2018, `}<a parentName="p" {...{
        "href": "http://www.mayoclinic.org/diseases-conditions/colon-cancer/in-depth/ostomy/art-20045825",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mayoclinic.org/diseases-conditions/colon-cancer/in-depth/ostomy/art-20045825`}</a>{`. Accessed 10 September 2020.`}</p>
    <p>{`“Pain relief: A guide for people with cancer.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, May 2015, `}{`[www.cancer.ca/~/media/cancer.ca/CW/publications/Pain relief/Pain-relief_2016_EN.pdf]`}{`(`}<a parentName="p" {...{
        "href": "http://www.cancer.ca/~/media/cancer.ca/CW/publications/Pain",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Pain`}</a>{` relief/Pain-relief_2016_EN.pdf). Accessed 28 March 2016.`}</p>
    <p>{`Palmer, Heather. “Brain Fog and Cancer: How You Can Fight Back.” `}<em parentName="p">{`Maximum Capacity`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.maximumcapacity.org/contact.html",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.maximumcapacity.org/contact.html`}</a>{`. Accessed 14 March 2016.`}</p>
    <p>{`“Pelvic Muscle Exercises – Kegel Exercises for Urinary Incontinence.” `}<em parentName="p">{`The Canadian Continence Foundation`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.canadiancontinence.ca/pdfs/pelvicmuscleexercises.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.canadiancontinence.ca/pdfs/pelvicmuscleexercises.pdf`}</a>{`. Accessed 14 March 2016.`}</p>
    <p>{`“Sexuality for the woman with cancer.” `}<em parentName="p">{`American Cancer Society`}</em>{`, 2013, `}<a parentName="p" {...{
        "href": "http://www.cancer.org/treatment/treatmentsandsideeffects/physicalsideeffects/sexualsideeffectsinwomen/sexulaityforthewoman",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/treatment/treatmentsandsideeffects/physicalsideeffects/sexualsideeffectsinwomen/sexulaityforthewoman`}</a>{`. Accessed 27 Jun 2016.`}</p>
    <p>{`“Types of Complementary Therapies.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, 2015, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/navigating-cancer-care/how-cancer-treated/integrative-medicine/types-complementary-therapies",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/navigating-cancer-care/how-cancer-treated/integrative-medicine/types-complementary-therapies`}</a>{`. Accessed 15 March 2016.`}</p>
    <p>{`Warr, David, Doris Howell, and ELLICSR Research Staff. “Managing Neuropathy after Cancer Treatment.” `}<em parentName="p">{`UHN`}</em>{`, 2013, `}<a parentName="p" {...{
        "href": "http://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Neuropathy_after_Cancer_Treatment.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Neuropathy_after_Cancer_Treatment.pdf`}</a>{`. Accessed 14 March 2016.`}</p>
    <p>{`“Weight Gain.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, 2015, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/navigating-cancer-care/side-effects/weight-gain",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/navigating-cancer-care/side-effects/weight-gain`}</a>{`. Accessed 27 June 2016.`}</p>
    <p>{`“Weight Gain.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/en/cancerinformation/diagnosis-and-treatment/managing-side-effects/weight-gain/?region=qc",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/en/cancerinformation/diagnosis-and-treatment/managing-side-effects/weight-gain/?region=qc`}</a>{`. Accessed 30 October 2016.`}</p>
    <p>{`“Weight Loss.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, 2015, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/navigating-cancer-care/side-effects/weight-loss",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/navigating-cancer-care/side-effects/weight-loss`}</a>{`. Accessed 14 March 2016.`}</p>
    <p>{`“What Are Common “Late or Long-Term Effects” of Cancer Treatment?” `}<em parentName="p">{`Cancer Bridges`}</em>{`, 2012, `}<a parentName="p" {...{
        "href": "http://www.cancerbridges.ca/2012/07/what-are-common-lateor-long-term-effects-of-cancer-treatment",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancerbridges.ca/2012/07/what-are-common-lateor-long-term-effects-of-cancer-treatment`}</a>{`. Accessed 14 March 2016.`}</p>
    <p>{`“What Are Complementary and Alternative Therapies?” `}<em parentName="p">{`MacMillan Cancer Support`}</em>{`, 10 December 2014, `}<a parentName="p" {...{
        "href": "http://www.macmillan.org.uk/information-and-support/coping/complementary-therapies/complementary-therapies-explained/complementary-alternative-therapies.html",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.macmillan.org.uk/information-and-support/coping/complementary-therapies/complementary-therapies-explained/complementary-alternative-therapies.html`}</a>{`. Accessed 14 March 2016.`}</p>
    <p>{`“Your Body after Treatment.” `}<em parentName="p">{`Dana-Farber Cancer Institute`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.danafarber.org/For-Adult-Cancer-Survivors/Caring-For-Yourself-After-Cancer/Your-Body-After-Treatment.aspx#pain",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.danafarber.org/For-Adult-Cancer-Survivors/Caring-For-Yourself-After-Cancer/Your-Body-After-Treatment.aspx#pain`}</a>{`. Accessed 28 June 2016.`}</p>
    <p>{`“Your sex life after vaginal cancer treatment.” `}<em parentName="p">{`Cancer Research UK`}</em>{`, 29 September 2015, `}<a parentName="p" {...{
        "href": "http://www.cancerresearchuk.org/about-cancer/type/vaginal-cancer/living/your-sex-life-after-vaginal-cancer-treatment",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancerresearchuk.org/about-cancer/type/vaginal-cancer/living/your-sex-life-after-vaginal-cancer-treatment`}</a>{`. Accessed 27 June 2016.`}</p>
    <ChapterTitle chapter="Chapter 3" title="EMOTIONS, FEARS AND RELATIONSHIPS" subtitle="Managing your emotional health" mdxType="ChapterTitle" />
    <p>{`“Anxiety.” `}<em parentName="p">{`Helpguide.org`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.helpguide.org/home-pages/anxiety.htm",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.helpguide.org/home-pages/anxiety.htm`}</a>{`. Accessed 9 June 2016`}</p>
    <p>{`Bauer, Amber. “The Power of Writing.” `}<em parentName="p">{`Cancer.Net`}</em>{`, June 2014, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/blog/2014-06/power-writing",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/blog/2014-06/power-writing`}</a>{`. Accessed 13 January 2021.`}</p>
    <p>{`“Body Image.” `}<em parentName="p">{`Livestrong`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.livestrong.org/we-can-help/emotional-and-physical-effects-of-treatment/body-image",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.livestrong.org/we-can-help/emotional-and-physical-effects-of-treatment/body-image`}</a>{`. Accessed 8 November 2020.`}</p>
    <p>{`Cantor, Robert C. `}<em parentName="p">{`And a time to live: Toward emotional well-being during the crisis of cancer`}</em>{`. New York: Harper & Row, 1980.`}</p>
    <p>{`“Changes to your appearance and body image.” `}<em parentName="p">{`MacMillan Cancer Support`}</em>{`, January 2017, `}<a parentName="p" {...{
        "href": "http://www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/changes-to-your-appearance-and-body-image",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/changes-to-your-appearance-and-body-image`}</a>{`. Accessed 9 October 2020.`}</p>
    <p>{`“Coping with Anger.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, January 2016, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/coping-with-cancer/managing-emotions/coping-with-anger",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/coping-with-cancer/managing-emotions/coping-with-anger`}</a>{`. Accessed June 6, 2016.`}</p>
    <p>{`“Coping with body image and self-esteem worries.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/en/cancer-information/living-with-cancer/your-emotions-and-cancer/coping-with-body-image-and-self-esteem/?region=qc",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/en/cancer-information/living-with-cancer/your-emotions-and-cancer/coping-with-body-image-and-self-esteem/?region=qc`}</a>{`. Accessed 8 November 2020.`}</p>
    <p>{`“Coping with Fear of Recurrence.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, August 2015, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/survivorship/dealing-cancer-recurrence",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/dealing-cancer-recurrence`}</a>{`. Accessed June 6, 2016.`}</p>
    <p>{`“Dealing with cancer recurrence.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, February 2016, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/survivorship/dealing-cancer-recurrence",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/dealing-cancer-recurrence`}</a>{`. Accessed June 6, 2016.`}</p>
    <p>{`Esplen, Mary Jane, Doris Howell and ELLICSR Research Team. “Managing Body Image Concerns after Cancer Treatment.” `}<em parentName="p">{`UHN`}</em>{`, March 2017, `}<a parentName="p" {...{
        "href": "http://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_body_image_problems_after_cancer_treatment.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_body_image_problems_after_cancer_treatment.pdf`}</a>{`. Accessed 8 November 2020.`}</p>
    <p>{`“Facing Forward: Life After Cancer Treatment.” `}<em parentName="p">{`National Cancer Institute`}</em>{`, 2014, `}<a parentName="p" {...{
        "href": "http://www.cancer.gov/publications/patient-education/facing-forward",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.gov/publications/patient-education/facing-forward`}</a>{`. Accessed February 22 2016.`}</p>
    <p>{`“Feeling alone after cancer treatment.” `}<em parentName="p">{`Gundersen Health System`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.gundersenhealth.org/health-wellness/live-happy/feeling-alone-after-cancer-treatment/",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.gundersenhealth.org/health-wellness/live-happy/feeling-alone-after-cancer-treatment`}</a>{`. Accessed 1 December 2020.`}</p>
    <p>{`“Feeling Lonely or Isolated.” `}<em parentName="p">{`Cancer Support Community`}</em>{`, April 2020, `}<a parentName="p" {...{
        "href": "http://www.cancersupportcommunity.org/sites/default/files/file/2020-12/CSC_CSS-FS_Feeling%20Lonely%20Isolated.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancersupportcommunity.org/sites/default/files/file/2020-12/CSC_CSS-FS_Feeling Lonely Isolated.pdf`}</a>{`. Accessed 9 October 2020.`}</p>
    <p>{`Harpham, Wendy S. `}<em parentName="p">{`Diagnosis, cancer: Your guide to the first months of healthy survivorship`}</em>{`. New York: W.W. Norton & Co, 2003.`}</p>
    <p>{`Holland, Jimmie. “Managing Uncertainty/Fear of Recurrence.” `}<em parentName="p">{`Memorial Sloan Kettering Cancer Center`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.mskcc.org/experience/living-beyond-cancer/information-survivors/emotional-effects/managing-uncertainty-fear-recurrence",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mskcc.org/experience/living-beyond-cancer/information-survivors/emotional-effects/managing-uncertainty-fear-recurrence`}</a>{`. Accessed 3 June 2016.`}</p>
    <p>{`Holland, Jimmie, and Sheldon Lewis. `}<em parentName="p">{`The human side of cancer: Living with Hope, Coping with Uncertainty`}</em>{`. New York: Quill, 2001.`}</p>
    <p>{`“Life after Cancer Treatment: Feeling lonely and isolated.” 2013, pp.22. `}<em parentName="p">{`Canadian Cancer Society`}</em>{`. `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/~/media/cancer.ca/CW/publications/Life%20after%20cancer%20treatment/32060-1-NO.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Life after cancer treatment/32060-1-NO.pdf`}</a>{`. Accessed 1 December 2020.`}</p>
    <p>{`“Your emotions and cancer.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/en/cancer-information/living-with-cancer/your-emotions-and-cancer/?region=qc",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/en/cancer-information/living-with-cancer/your-emotions-and-cancer/?region=qc`}</a>{`. Accessed 9 October 2020.`}</p>
    <p>{`“Loneliness and isolation.” `}<em parentName="p">{`Macmillan Cancer Support`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/loneliness-and-isolation",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/loneliness-and-isolation`}</a>{`. Accessed 1 December 2020.`}</p>
    <p>{`“Loneliness: Another Forgotten Challenge of Cancer.” `}<em parentName="p">{`Cancer Foundation`}</em>{`, January 2019, `}<a parentName="p" {...{
        "href": "http://www.ottawacancer.ca/loneliness-and-cancer/",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.ottawacancer.ca/loneliness-and-cancer`}</a>{`. Accessed 9 October 2020.`}</p>
    <p>{`Magee, Sherri, and Kathy Scalzo. `}<em parentName="p">{`Picking Up the pieces: Moving Forward After Surviving Cancer`}</em>{`. Vancouver: Raincoast Books, 2006.`}</p>
    <p>{`Maheu, Christine, Rosemary Cashman, Kyla Johnson and Maureen Parkinson. “Changes in physical appearance and body image.” `}<em parentName="p">{`Cancer and Work`}</em>{`, 2020., `}<a parentName="p" {...{
        "href": "http://www.cancerandwork.ca/healthcare-providers/cancers-impact-on-work/effects-physical/",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancerandwork.ca/healthcare-providers/cancers-impact-on-work/effects-physical`}</a>{`. Accessed 9 October 2020.`}</p>
    <p>{`Mayo Clinic Staff. “Cancer survivors: Managing your emotions after cancer treatment.” `}<em parentName="p">{`Mayo Foundation for Medical Education and Research`}</em>{`, 8 Oct 2014, `}<a parentName="p" {...{
        "href": "http://www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-survivor/ART-20047129",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-survivor/ART-20047129`}</a>{`. Accessed 17 May 2016.`}</p>
    <p>{`Mayo Clinic Staff. “What you can do to nurture relationships with friends and family.” `}<em parentName="p">{`Mayo Foundation for Medical Education and Research`}</em>{`, 8 Oct 2014. `}<a parentName="p" {...{
        "href": "http://www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-survivor/art-20045378?pg=2",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mayoclinic.org/diseases-conditions/cancer/in-depth/cancer-survivor/art-20045378?pg=2`}</a>{`. Accessed 30 June 2016.`}</p>
    <p>{`Napolitano, Esther. “Six Tips for Managing Fear of Recurrence.” `}<em parentName="p">{`Memorial Sloan Kettering Cancer Center`}</em>{`, 24 Mar 2014, `}<a parentName="p" {...{
        "href": "http://www.mskcc.org/blog/sixtips-managing-fear-recurrence",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mskcc.org/blog/sixtips-managing-fear-recurrence`}</a>{`. Accessed 3 June 2016.`}</p>
    <p>{`Neff, Kristin. “What is Self-Compassion?” `}<em parentName="p">{`Self-Compassion`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "https://self-compassion.org",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`self-compassion.org`}</a>{`. Accessed 13 January 2021.`}</p>
    <p>{`Saulsman, L., Nathan, P., Lim, L., Correia, H., Anderson, R., and Campbell, B. “What? Me Worry!?! Mastering Your Worries. Module 9: Accepting Uncertainty.” `}<em parentName="p">{`Centre for Clinical Interventions`}</em>{`, 2015, `}<a parentName="p" {...{
        "href": "http://www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=46",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=46`}</a>{`. Accessed 22 May 2016.`}</p>
    <p>{`Savard, Josée. `}<em parentName="p">{`Faire face au cancer : avec la pensée réaliste`}</em>{`. Montréal: Flammarion Québec, 2010, pp.93-117.`}</p>
    <p>{`“Self-Image and Cancer.” `}<em parentName="p">{`Cancer.Net`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.net/coping-with-cancer/managing-emotions/self-image-and-cancer",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/coping-with-cancer/managing-emotions/self-image-and-cancer`}</a>{`. Accessed 8 November 2020`}</p>
    <p>{`Taylor, Katy. “Cancer Changes a Person’s Perspective On Life and It Can Lead to Loneliness.” `}<em parentName="p">{`The Breast Cancer Site News`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "https://blog.thebreastcancersite.greatergood.com/cancer-loneliness/",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`blog.thebreastcancersite.greatergood.com/cancer-loneliness`}</a>{`. Accessed 9 October 2020.`}</p>
    <p>{`“The loneliness of cancer.” `}<em parentName="p">{`City of Hope`}</em>{`, July 2018, `}<a parentName="p" {...{
        "href": "http://www.cityofhope.org/living-well/the-loneliness-of-cancer",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cityofhope.org/living-well/the-loneliness-of-cancer`}</a>{`. Accessed 1 December 2020.`}</p>
    <p>{`“What happens now? Follow up care for breast cancer patients.” `}<em parentName="p">{`Cancer Care Nova Scotia`}</em>{`, 2016, `}<a parentName="p" {...{
        "href": "http://www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx`}</a>{`. Accessed 1 July 2016.`}</p>
    <p>{`“What happens now? Follow up care for colon cancer patients.” `}<em parentName="p">{`Cancer Care Nova Scotia`}</em>{`, 2016, `}<a parentName="p" {...{
        "href": "http://www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx`}</a>{`. Accessed 1 July 2016.`}</p>
    <p>{`“What happens now? Follow up care for rectal cancer patients.” `}<em parentName="p">{`Cancer Care Nova Scotia`}</em>{`, 2016, `}<a parentName="p" {...{
        "href": "http://www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx`}</a>{`. Accessed 1 July 2016.`}</p>
    <p>{`“What happens now? Follow up care for thyroid cancer patients.” `}<em parentName="p">{`Cancer Care Nova Scotia`}</em>{`, 2016, `}<a parentName="p" {...{
        "href": "http://www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/en/home/patientsfamilies/survivorship/default.aspx`}</a>{`. Accessed 1 July 2016.`}</p>
    <p>{`“Worrying that cancer will come back.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/en/cancer-information/cancer-journey/life-after-cancer/worrying-that-the-cancer-will-come-back/?region=qc",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/en/cancer-information/cancer-journey/life-after-cancer/worrying-that-the-cancer-will-come-back/?region=qc`}</a>{`. Accessed 1 July 2016.`}</p>
    <p>{`“Your feelings after cancer treatment.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/en/cancer-information/cancer-journey/life-after-cancer/your-feelings-after-cancer/?region=qc",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/en/cancer-information/cancer-journey/life-after-cancer/your-feelings-after-cancer/?region=qc`}</a>{`. Accessed 1 July 2016.`}</p>
    <p>{`“Your feelings after cancer treatment.” `}<em parentName="p">{`Macmillan Cancer Support`}</em>{`, 31 July 2014 MacMillan Cancer Support, `}<a parentName="p" {...{
        "href": "http://www.macmillan.org.uk/informationand-support/coping/your-emotions/dealing-with-your-emotions/your-feelings-after-cancer-treatment.html",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.macmillan.org.uk/informationand-support/coping/your-emotions/dealing-with-your-emotions/your-feelings-after-cancer-treatment.html`}</a>{`. Accessed 6 June 2016.`}</p>
    <ChapterTitle chapter="Chapter 4" title="REGAINING FUNCTION" subtitle="Promoting and Maintaining Health" mdxType="ChapterTitle" />
    <p>{`“Cancer-Related Fatigue.” `}<em parentName="p">{`Leukemia and Lymphoma Society`}</em>{`, 2015, `}<a parentName="p" {...{
        "href": "http://www.lls.org/treatment/managing-side-effects/cancer-related-fatigue",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.lls.org/treatment/managing-side-effects/cancer-related-fatigue`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“Complementary Therapies: A guide for people with cancer.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, May 2014, `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/~/media/cancer.ca/CW/publications/Complementary%20therapies/Complementary-therapies-2014-EN.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Complementary therapies/Complementary-therapies-2014-EN.pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“Creating an Action Plan for Managing Your Fatigue.” `}<em parentName="p">{`My Cancer Fatigue`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.mycancerfatigue.ca/Home/My_Action_Plan",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.mycancerfatigue.ca/Home/My_Action_Plan`}</a>{`. Accessed 23 November 2020.`}</p>
    <p>{`“Eating Well When You Have Cancer.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, May 2014, `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/~/media/cancer.ca/CW/publications/Eating%20well%20when%20you%20have%20cance/Eating-well-when-you-have-cancer-2014-EN.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Eating well when you have cance/Eating-well-when-you-have-cancer-2014-EN.pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“ELLICSR Blog.” `}<em parentName="p">{`ELLICSR: Health, Wellness & Cancer Survivorship Centre`}</em>{`. n.d. `}<a parentName="p" {...{
        "href": "http://www.ellicsr.ca/en/connect_with_ELLICSR/ellicsr_blog",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.ellicsr.ca/en/connect_with_ELLICSR/ellicsr_blog`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“ELLICSR Kitchen.” `}<em parentName="p">{`ELLICSR: Health, Wellness and Cancer Survivorship Centre`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.ellicsr.ca/en/clinics_programs/ellicsr_kitchen",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.ellicsr.ca/en/clinics_programs/ellicsr_kitchen`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`Howell, Doris. “Managing Sleep Problems after Cancer.” `}<em parentName="p">{`UHN`}</em>{`, May 2013, `}<a parentName="p" {...{
        "href": "http://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Sleep_Problems_after_Cancer.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Sleep_Problems_after_Cancer.pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`Howell, Doris, Christy Brissette, and ELLICSR Research Staff. “Managing Weight Gain after Cancer Treatment.” `}<em parentName="p">{`UHN`}</em>{`, June 2013, `}<a parentName="p" {...{
        "href": "http://www.uhn.ca/Patients-Families/Health_Information/Health_Topics/Documents/Managing_Weight_Gain_after_Cancer_Treatment.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/Patients-Families/Health_Information/Health_Topics/Documents/Managing_Weight_Gain_after_Cancer_Treatment.pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`Howell, Doris, Christy Brissette, and ELLICSR Research Team. “Managing Weight Loss after Cancer Treatment.” `}<em parentName="p">{`UHN`}</em>{`, June 2013, `}<a parentName="p" {...{
        "href": "http://www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Weight_Loss_after_Cancer_Treatment.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/PatientsFamilies/Health_Information/Health_Topics/Documents/Managing_Weight_Loss_after_Cancer_Treatment.pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“Life after Cancer Treatment.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/~/media/cancer.ca/CW/publications/Life%20after%20cancer%20treatment/life_after_cancer_treatment_en_may2013.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Life after cancer treatment/life_after_cancer_treatment_en_may2013.pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“Lifestyle Changes That Make a Difference: Nutrition and Physical Activity Guidelines for Cancer Survivors.” `}<em parentName="p">{`American Cancer Society`}</em>{`, 2012, `}<a parentName="p" {...{
        "href": "http://www.cancer.org/acs/groups/content/@editorial/documents/document/acspc-037186.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/acs/groups/content/@editorial/documents/document/acspc-037186.pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“Living Well: Walk Your Way to Better Health.” Forward, vol. 2, no. 2, Fall 2014, pp.6-7. `}<em parentName="p">{`BC Cancer Agency`}</em>{`, `}<a parentName="p" {...{
        "href": "https://digital.canadawide.com/i/389164-fall-2014",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`digital.canadawide.com/i/389164-fall-2014`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“Measuring Physical Activity Intensity.” `}<em parentName="p">{`Centers for Disease Control and Prevention`}</em>{`. June 2015, `}<a parentName="p" {...{
        "href": "http://www.cdc.gov/physicalactivity/basics/measuring/index.html",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cdc.gov/physicalactivity/basics/measuring/index.html`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“Nutrition and Physical Activity During and after Cancer Treatment: Answers to Common Questions.” `}<em parentName="p">{`The American Cancer Society`}</em>{`, 4 November 2013, `}<a parentName="p" {...{
        "href": "http://www.cancer.org/treatment/survivorshipduringandaftertreatment/nutritionforpeoplewithcancer/nutrition-and-physical-activity-during-and-after-cancer-treatment-answers-to-common-questions",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/treatment/survivorshipduringandaftertreatment/nutritionforpeoplewithcancer/nutrition-and-physical-activity-during-and-after-cancer-treatment-answers-to-common-questions`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“Nutrition and Physical Activity Guidelines for Cancer Prevention.” `}<em parentName="p">{`American Cancer Society`}</em>{`, January 2016, `}<a parentName="p" {...{
        "href": "http://www.cancer.org/acs/groups/cid/documents/webcontent/002577-pdf.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/acs/groups/cid/documents/webcontent/002577-pdf.pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“On-the-Go Guides.” `}<em parentName="p">{`Cancer and Careers`}</em>{`, 2016, `}<a parentName="p" {...{
        "href": "http://www.cancerandcareers.org/publication_orders/new",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancerandcareers.org/publication_orders/new`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“Our Cancer Prevention Recommendations.” `}<em parentName="p">{`World Cancer Research Fund International`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.wcrf.org/int/research-we-fund/our-cancer-prevention-recommendations",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.wcrf.org/int/research-we-fund/our-cancer-prevention-recommendations`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`Phan, Stephanie, Aronela Benea, and Linda Durkee. “Reclaim Your Energy: Coping with Cancer-Related Fatigue.” `}<em parentName="p">{`UHN`}</em>{`, May 2009, `}<a parentName="p" {...{
        "href": "http://www.uhn.ca/Patients-Families/Health_Information/Health_Topics/Documents/Reclaim_Your_Energy_Coping_with_Cancer_Fatigue.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.uhn.ca/Patients-Families/Health_Information/Health_Topics/Documents/Reclaim_Your_Energy_Coping_with_Cancer_Fatigue.pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“Physical Activity and Cancer.” `}<em parentName="p">{`American Cancer Society`}</em>{`, 2007, `}<a parentName="p" {...{
        "href": "http://www.cancer.org/acs/groups/content/@nho/documents/document/physicalactivityandcancerpdf.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/acs/groups/content/@nho/documents/document/physicalactivityandcancerpdf.pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`The Weight Watchers Research Department. “Exercise Intensity Levels: How to measure exercise intensity.” `}<em parentName="p">{`Weight Watchers International Inc.`}</em>{`, 2016, `}<a parentName="p" {...{
        "href": "http://www.weightwatchers.com/util/art/index_art.aspx?tabnum=1&art_id=20971",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.weightwatchers.com/util/art/index_art.aspx?tabnum=1&art_id=20971`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“What Happens Now?: Follow up Care for Breast Cancer Patients.” `}<em parentName="p">{`Cancer Care Nova Scotia`}</em>{`, 2014, `}<a parentName="p" {...{
        "href": "http://www.cancercare.ns.ca/site-cc/media/cancercare/Follow%20Up%20Care%20for%20Breast%20Cancer%20Patients%281%29.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/site-cc/media/cancercare/Follow Up Care for Breast Cancer Patients%281%29.pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“What Happens Now?: Follow up Care for Colon Cancer Patients.” `}<em parentName="p">{`Cancer Care Nova Scotia`}</em>{`, 2014, `}<a parentName="p" {...{
        "href": "http://www.cancercare.ns.ca/site-cc/media/cancercare/jun15Follow%20Up%20Care%20for%20Colon%20Cancer%20Patients%281%29.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/site-cc/media/cancercare/jun15Follow Up Care for Colon Cancer Patients%281%29.pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“What Happens Now?: Follow up Care for Rectal Patients.” `}<em parentName="p">{`Cancer Care Nova Scotia`}</em>{`, 2014, `}<a parentName="p" {...{
        "href": "http://www.cancercare.ns.ca/sitecc/media/cancercare/Follow%20Up%20Care%20for%20Rectal%20Cancer%20Patients(1).pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/sitecc/media/cancercare/Follow Up Care for Rectal Cancer Patients(1).pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <p>{`“What Happens Now?: Follow up Care for Thyroid Patients.” `}<em parentName="p">{`Cancer Care Nova Scotia`}</em>{`, 2014. `}<a parentName="p" {...{
        "href": "http://www.cancercare.ns.ca/site-cc/media/cancercare/Follow%20Up%20Care%20for%20Thyroid%20Cancer%20Patients(1).pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancercare.ns.ca/site-cc/media/cancercare/Follow Up Care for Thyroid Cancer Patients(1).pdf`}</a>{`. Accessed 22 March 2016.`}</p>
    <ChapterTitle chapter="Chapter 5" title="BACK TO WORK" subtitle="Planning your return" mdxType="ChapterTitle" />
    <p>{`“Back to Work after Cancer.” `}<em parentName="p">{`Cancer and Careers`}</em>{`, 2013, `}<a parentName="p" {...{
        "href": "http://www.cancerandcareers.org/en/at-work/back-to-work-after-cancer/back-towork",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancerandcareers.org/en/at-work/back-to-work-after-cancer/back-towork`}</a>{`. Accessed 9 October 2015.`}</p>
    <p>{`“Cancer and Work.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, 25 February 2016, `}<a parentName="p" {...{
        "href": "http://www.youtube.com/watch?v=vAtkO9obD-g",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.youtube.com/watch?v=vAtkO9obD-g`}</a>{`. Accessed March 1 2016.`}</p>
    <p>{`“Cancer and Workplace Discrimination.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, July 2015, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/survivorship/life-after-cancer/cancer-and-workplace-discrimination",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/life-after-cancer/cancer-and-workplace-discrimination`}</a>{`. Accessed 9 October 2015.`}</p>
    <p>{`“Eligibility for a disability pension.” `}<em parentName="p">{`Retraite Québec`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.rrq.gouv.qc.ca/en/invalidite/definition/Pages/admissibilite.aspx?_ga=1.199723374.1304251209.1436806882",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.rrq.gouv.qc.ca/en/invalidite/definition/Pages/admissibilite.aspx?_ga=1.199723374.1304251209.1436806882`}</a>{`. Accessed 6 January 2016.`}</p>
    <p>{`“Facing Forward: Life After Cancer Treatment.” `}<em parentName="p">{`National Cancer Institute`}</em>{`, 2014, `}<a parentName="p" {...{
        "href": "http://www.cancer.gov/publications/patient-education/facing-forward",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.gov/publications/patient-education/facing-forward`}</a>{`. Accessed 22 February 2016.`}</p>
    <p>{`“Finding a Job after a Cancer.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, July 2015, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/survivorship/life-after-cancer/finding-job-aftercancer",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/life-after-cancer/finding-job-aftercancer`}</a>{`. Accessed 9 October 2015.`}</p>
    <p>{`“Going Back to Work after Cancer.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, July 2014, `}<a parentName="p" {...{
        "href": "http://www.cancer.net/survivorship/life-after-cancer/going-back-workafter-cancer",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/life-after-cancer/going-back-workafter-cancer`}</a>{`. Accessed 9 October 2015.`}</p>
    <p>{`“Job Search Tools.” `}<em parentName="p">{`Cancer and Careers`}</em>{`, 2013, `}<a parentName="p" {...{
        "href": "http://www.cancerandcareers.org/en/looking-for-work/exploring-your-options/job-search-tools",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancerandcareers.org/en/looking-for-work/exploring-your-options/job-search-tools`}</a>{`. Accessed 9 October 2015.`}</p>
    <p>{`Lambert, Sylvie. “Coping Together: A Practical Guide for Couples Facing Cancer.” St Mary’s Research Centre. `}<em parentName="p">{`Guidebook for Family Caregivers`}</em>{`. Montreal: St. Mary’s Hospital Foundation, 2013.`}</p>
    <p>{`“Life after Cancer Treatment.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, May 2013, `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/~/media/cancer.ca/CW/publications/Life%20after%20cancer%20treatment/Life-%20After-Cancer-Treatment-EN-May%202016.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/~/media/cancer.ca/CW/publications/Life after cancer treatment/Life- After-Cancer-Treatment-EN-May 2016.pdf`}</a>{`. Accessed 30 October 2015.`}</p>
    <p>{`Parkinson, Maureen. Cancer and Returning to Work: A Practical Guide for Cancer Patients. 2nd ed. `}<em parentName="p">{`Vancouver: BC Cancer Agency`}</em>{`, 2015, `}<a parentName="p" {...{
        "href": "http://www.bccancer.bc.ca/coping-and-support-site/Documents/Cancer_Returning%20to_Work_Manual_Interactive%20(3).pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.bccancer.bc.ca/coping-and-support-site/Documents/Cancer_Returning to_Work_Manual_Interactive (3).pdf`}</a>{`. Accessed 9 October 2015.`}</p>
    <p>{`“Returning to work after cancer.” `}<em parentName="p">{`Quebec Cancer Foundation`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://fqc.qc.ca/en/information/after-cancer/returning-to-work-2",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`fqc.qc.ca/en/information/after-cancer/returning-to-work-2`}</a>{`. Accessed 6 January2016.`}</p>
    <p>{`Samson, Mélanie. “Planifier La Transition Après Un Diagnostic De Cancer.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, May 2014, `}<a parentName="p" {...{
        "href": "http://www.aqsp.org/wp-content/uploads/2014/05/Congr%C3%A8s-2014-B5-M%C3%A9lanie-Samson_annexes.pdf",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.aqsp.org/wp-content/uploads/2014/05/Congr%C3%A8s-2014-B5-M%C3%A9lanie-Samson_annexes.pdf`}</a>{`. Accessed 1 November 2015.`}</p>
    <p>{`“Services publics d’orientation.” `}<em parentName="p">{`Ordre des conseillers et conseillères d’orientation du Québec`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.orientation.qc.ca/services-publics-dorientation",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.orientation.qc.ca/services-publics-dorientation`}</a>{`. Accessed 7 December 2015.`}</p>
    <p>{`“Stopping Work after Cancer Treatment.” `}<em parentName="p">{`American Society of Clinical Oncology`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.net/survivorship/life-after-cancer/stoppingwork-after-cancer-treatment",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.net/survivorship/life-after-cancer/stoppingwork-after-cancer-treatment`}</a>{`. Accessed 9 October 2015.`}</p>
    <p>{`“Work and Finances.” `}<em parentName="p">{`Canadian Cancer Society`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.cancer.ca/en/cancer-information/cancer-journey/life-after-cancer/work-and-finances/?region=qc",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.ca/en/cancer-information/cancer-journey/life-after-cancer/work-and-finances/?region=qc`}</a>{`. Accessed 20 October 2015.`}</p>
    <ChapterTitle chapter="Chapter 6" title="FAMILY CAREGIVER SUPPORT" subtitle="Taking care of the caregiver" mdxType="ChapterTitle" />
    <p>{`Becker, Saul. “Young Carers.” `}<em parentName="p">{`The Blackwell Encyclopaedia of Social Work`}</em>{`. Ed. M. Davies. Vancouver: Blackwell Publishers Ltd., 2000. 378.`}</p>
    <p>{`“Facing Forward: When Someone You Love Has Completed Cancer Treatment.” `}<em parentName="p">{`National Cancer Institute`}</em>{`, 2014, `}<a parentName="p" {...{
        "href": "http://www.cancer.gov/Publications/patient-education/someone-you-love-completed-cancer-treatment",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.gov/Publications/patient-education/someone-you-love-completed-cancer-treatment`}</a>{`. Accessed 21 February 2016`}</p>
    <p>{`“How to Make Friends: Tips on Meeting People and Building Strong Friendships.” `}<em parentName="p">{`Helpguide.org`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.helpguide.org/articles/relationships/how-to-make-friends.htm#how",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.helpguide.org/articles/relationships/how-to-make-friends.htm#how`}</a>{`. Accessed 21 February 2016.`}</p>
    <p>{`Lambert, Sylvie. “Coping Together: A Practical Guide for Couples Facing Cancer.” St Mary’s Research Centre. `}<em parentName="p">{`Guidebook for Family Caregivers`}</em>{`. Montreal: St. Mary’s Hospital Foundation, 2013.`}</p>
    <ChapterTitle chapter="Chapter 7" title="WHERE TO GET RELIABLE HEALTH INFORMATION" subtitle="How to search, how to evaluate" mdxType="ChapterTitle" />
    <p>{`“Cancer Information on the Internet.” `}<em parentName="p">{`American Cancer Society`}</em>{`, 1 April 2014, `}<a parentName="p" {...{
        "href": "http://www.cancer.org/cancer/cancerbasics/cancerinformation-on-the-internet",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.cancer.org/cancer/cancerbasics/cancerinformation-on-the-internet`}</a>{`. Accessed 22 June 2016.`}</p>
    <p>{`“Evaluating Internet Resources.” `}<em parentName="p">{`Georgetown University Library`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.library.georgetown.edu/tutorials/research-guides/evaluatinginternet-content",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.library.georgetown.edu/tutorials/research-guides/evaluatinginternet-content`}</a>{`. Accessed 22 June 2016.`}</p>
    <p>{`“Evaluating Internet Sources.” `}<em parentName="p">{`University of Illinois at Urbana-Champaign University Library`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.library.illinois.edu/ugl/howdoi/webeval.html",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.library.illinois.edu/ugl/howdoi/webeval.html`}</a>{`. Accessed 2 February 2016`}</p>
    <p>{`“How to Evaluate Health Information.” `}<em parentName="p">{`McGill University Health Centre Libraries`}</em>{`, n.d., `}<a parentName="p" {...{
        "href": "http://www.muhclibraries.ca/patients/how-to-evaluate-healthinformation",
        "className": "link link--external",
        "target": "_blank",
        "rel": "noopener"
      }}>{`www.muhclibraries.ca/patients/how-to-evaluate-healthinformation`}</a>{`. Accessed 16 July 2016.`}</p>
    <p>{`Mayo Clinic Staff. `}<em parentName="p">{`Mayo Clinic Book of Alternative Medicine`}</em>{`. 2nd ed. Birmingham, AL: Oxmoor House, 2010. 59.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      